.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  padding-top: 6px;
}

.mdc-text-field:not(.mdc-text-field--invalid, .mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
  .mdc-notched-outline > * {
    border-color: var(--Primary) !important;
  }
}

mat-datepicker-toggle > .mat-mdc-icon-button {
  --mdc-icon-button-icon-color: var(--Primary);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  --mdc-outlined-text-field-label-text-color: var(--quantum-on-surface-high-emphasis);
}

.mat-mdc-option .mat-icon,
.mat-mdc-option {
  vertical-align: middle;
}

.mat-mdc-text-field-wrapper {
  background-color: var(--Surface);
  border-radius: 0.5rem !important;
}

mat-paginator * .mat-mdc-text-field-wrapper {
  background-color: inherit;
}

.mat-mdc-form-field.mat-focused .mdc-floating-label mat-label {
  padding-block: 0.25rem;
  border-radius: 0.125rem;
  color: var(--Primary);
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.mat-mdc-paginator-icon {
  --mat-paginator-enabled-icon-color: var(--Primary);
  --mat-paginator-disabled-icon-color: var(--Primary-Disabled);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 0.875rem !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 0.875rem !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--Surface);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: block !important;
}

.mat-mdc-select {
  font-size: 0.875rem;
}

.mat-mdc-checkbox,
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--On-Primary);
}
