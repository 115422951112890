@use 'abstracts/mixins';

// Specific component overrides, pieces that are not in line with the general
// theming

a {
  color: var(--Primary);
}

.mat-ripple {
  display: none;
}

.mat-tree-node {
  font-size: 1rem;
  height: 2.25rem;
  min-height: 1.8em !important;

  button.mat-mdc-icon-button {
    height: 3.25rem;
    min-height: 1.8em;
    line-height: 1.6em;
  }
}

.hide {
  visibility: hidden;
}

img:not([src]) {
  visibility: hidden;
}

sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.tooltip-list {
  white-space: pre;
}

.tooltip-list>* {
  text-align: left !important;
}

.vendor-agent__dialog-container .mat-mdc-dialog-container {
  padding: 0px;
}

app-help-centre {
  position: absolute;
  inset: 0;
}

.property_takeover__address {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-end: 1.5rem;
  color: var(--Primary);
  font-size: 1.1rem;
}

.mat-form-field-flex {
  align-items: center;
}

.mat-form-field-outline {
  background-color: var(--Surface);
  color: var(--Outlined);
  border-radius: 0.5rem;
}

.mat-form-field.mat-focused .mat-form-field-label mat-label {
  background-color: var(--Surface);
  padding-block: 0.25rem;
  border-radius: 0.125rem;
}

.mat-step-icon-content {
  color: var(--Surface);
}

.mat-expansion-panel-header-title {

  & h1,
  cdk-global-overlay-wrapper h2,
  h3 {
    margin-block-end: 0.5rem;
  }
}

.mat-expansion-panel-body {
  padding: 0.5rem 1rem !important;
}

.leaflet-container a.leaflet-popup-close-button {
  cursor: pointer;
}

mat-stepper {
  .mat-vertical-content-container {
    margin-inline-start: 12px;

    @include mixins.respond(s) {
      margin-inline-start: 0;
    }
  }

  .mat-vertical-content {
    @include mixins.respond(s) {
      padding: 0;
    }
  }

  .mat-vertical-stepper-header {
    padding-block: 24px;
    padding-inline: 0;
  }

  .mat-stepper-vertical-line::before {
    @include mixins.respond(s) {
      left: 0.625rem !important;
    }
  }
}

#map .leaflet-control-zoom {
  @media (max-width: 37.5em) {
    margin-block-end: 3rem;
  }
}

.search-autocomplete {
  max-height: 60vh !important;
}

.leaflet-popup.draw-popup {
  font-family: var(--OpenSans-font-stack);
  text-shadow:
    1px 0 var(--Neutral-50),
    -1px 0 var(--Neutral-50),
    0 1px var(--Neutral-50),
    0 -1px var(--Neutral-50),
    1px 1px var(--Neutral-50),
    -1px -1px var(--Neutral-50),
    1px -1px var(--Neutral-50),
    -1px 1px var(--Neutral-50);
}

.sectionsMobileMenu {
  width: 100vw;
  box-shadow: 0 4px 7px -5px var(--V-Grey-Dark);
  border-radius: 0 !important;
  max-width: initial !important;
}

#topMenu {
  display: none;

  @media (max-width: 768px) {
    &.isVisible {
      display: flex;
    }
  }
}

.sectionMobileMenuBody {
  scroll-padding-top: 60px;
}

.leaflet-measure-path-measurement {
  position: absolute;
  font-size: 0.875rem;
  font-family: var(--Quicksand-font-stack);
  font-weight: 800;
  color: black;
  text-shadow:
    -1px 0 0 white,
    -1px -1px 0 white,
    0 -1px 0 white,
    1px -1px 0 white,
    1px 0 0 white,
    1px 1px 0 white,
    0 1px 0 white,
    -1px 1px 0 white;
  white-space: nowrap;
  transform-origin: 0;
  pointer-events: none;
}

.leaflet-measure-path-measurement>div {
  position: relative;
  margin-top: -25%;
  left: -50%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--Surface) inset;
}

.mat-mdc-menu-item.mdc-list-item {
  --mdc-theme-text-primary-on-background: var(--Primary);
  font-size: 0.875rem;

  & span {
    color: var(--Neutral-900);
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.mat-mdc-menu-item {
  --mat-menu-item-icon-color: var(--Primary);
}

.cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--Neutral-900);
}

.mat-calendar-body-cell-content {
  font-weight: var(--quantum-ref-font-weights-semi-bold);
}

.ng-scroll-content {
  position: relative;
}

.mobile-hide {
  @include mixins.respond(xs) {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.mat-bottom-sheet-container {
  border-start-start-radius: 1rem;
  border-start-end-radius: 1rem;
  overflow-x: hidden !important;
}
